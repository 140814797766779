.quill {
    height: calc(100vh - 200px);
    width: auto;
    border: 1px solid #e5e7eb;
    margin: 0;
    padding: 0;
    overflow-y: scroll;
    overflow-x: hidden;
    position: relative;
}
.quill .ql-container.ql-snow {
    background-color: #f9fafb;
    border-radius: 6px;
    border: none;
}
.quill .ql-toolbar.ql-snow {
    display: flex;
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: #ffffff;
    justify-content: space-evenly;
    align-items: center;
    border: none;
    height: 48px;
    box-shadow: 2.58px 14px 21px -1px rgba(6, 25, 56, 0.08);
}
.quill .ql-toolbar.ql-snow .ql-formats {
    display: flex;
    gap: 2rem;
}
