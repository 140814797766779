.nav-pills {
    border: none;
    /* background-color: #f3f4f6; */
    margin-right: 10px;
}

.nav-pills .nav-item {
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 2.4rem;
    color: #f3f4f6;
    border: none;
    background-color: #f3f4f6;
    margin-right: 13px;
}

.nav-pills .nav-link {
    border: none;
    margin-top: 3px;
    margin-bottom: 1.6px;
    padding-left: 1.2rem;
    padding-right: 1.2rem;
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
}
.nav-pills a {
    color: #6b7280;
}

.nav-pills .nav-item.show .nav-link,
.nav-pills .nav-link.active {
    color: #1f2937;
    background-color: #f3f4f6;
    padding-left: 1.2rem;
    padding-right: 1.2rem;
    border-bottom: 3px solid #6366f1;
}
